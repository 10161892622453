.play {
  background: eggshell;
  padding: 5%;
  padding-top: 10px;
  text-align: center;
  color: #626567;
}

.play-projects {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto;
  margin-top: 10%;
  width: 80%;
}

.showcase-wrapper {
  margin: 0 60px 60px 60px;
}

.preview-wrapper {
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}