.work-wrapper {
  background: rgb(255, 255, 251);
  overflow: hidden;
}

.work {
  color: rgb(98, 101, 103, 0.8);
  width: 800px;
  max-width: 94%;
  margin: 0 auto;
  font-size: 14px;
}

.work-title {
  text-align: center;
}

.bold-it {
  font-weight: 600;
  font-size: 20px;
}

.fe-focus {
  font-weight: 600;
  font-size: 20px;
  background: radial-gradient(circle, rgb(0 106 255) 0%, rgb(255 0 231) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro-wrapper {
  width: 283px;
  padding: 10px 20px;
}

.intro-text {
  display: flex;
  align-items: flex-end;
}

.intro-text-right {
  text-align: right;
}

.intro-sec {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: bottom;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid rgb(196, 196, 193, 0.2);
}

.intro-hint {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.intro-hint a {
  color: inherit;
  font-weight: 600;
}

.core-strengths {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgb(196, 196, 193, 0.2);
  padding: 30px 0;
}

.wordy-section {
  margin-bottom: 30px;
}

.wordy-section-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 3px;
  font-family: sans-serif;
}