.time-widget-wrapper {
  background-color: black;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.sky {
  background-color: lightblue;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.time-land {
  width: 100%;
  height: 100%;
  background-color: green;
  border-radius: 100%;
}

.time-land-wrapper {
  width: 100%;
  height: 50%;
  bottom: 0;
  position: absolute;
  background: darkgreen;
  border-radius: 100%;
  transform: translateY(100%) scale(2);
  z-index: 1;
}

.light-filter {
  width: 100%;
  height: 100%;
  transition: opacity 2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.land {
  border-radius: 100%;
}

.time-land-night {
  background: rgb(3, 22, 3);
}
.time-land-night-peak {
  background: rgb(0, 48, 0);
}
.time-land-morning {
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-morning-peak {
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-noon {
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-noon-peak {
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-evening {
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-evening-peak {
  background: rgb(22, 56, 22);
}

.time-sky-night {
  background: rgb(0, 0, 66);
}
.time-sky-night-peak {
  background: black;
}
.time-sky-morning {
  background: rgb(0, 0, 133);;
}
.time-sky-morning-peak {
  background: linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
}
.time-sky-noon {
  background: lightblue;
}
.time-sky-noon-peak {
  background: lightblue;
}
.time-sky-evening {
  background: linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
}
.time-sky-evening-peak {
  background: darkblue;
}