.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgoldenrodyellow;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.little-button {
  width: 20%;
  height: 10%;
  background-color: cornflowerblue;
  border-radius: 4px;
}

.little-button:hover {
  background-color: red;
}