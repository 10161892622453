.the-button {
  padding: 10px 35px;
  background: cornflowerblue;
  border: none;
  color: ghostwhite;
  font-family: monospace;
  color: #fff;
  background-color: #6496c8;
  border-radius: 15px;
  box-shadow: 0 5px #27496d;
  font-size: 1.5em;
  cursor: pointer;
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  transition: top linear 0.5s, left linear 0.5s;
}

.the-button:disabled {
  cursor: not-allowed;
  background: red;
  box-shadow: 0 5px darkred;
}

.the-rest {
  width: 100%;
  background-color: LightYellow;
  overflow: hidden;
}
