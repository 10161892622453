.attribute-bold {
  font-weight: 600;
}

.creature-machine {
  display: flex;
  position: relative;
  background-color: hsl(0, 100%, 70%);
  padding: 25px;
  border-radius: 8px;
  border: solid hsl(0, 100%, 50%) 3px;
  box-shadow: 0px 0px 100px black;
}

.creature-machine-image {
  background-color: white;
  border-radius: 8px;
  border: solid hsl(0, 100%, 50%) 3px;
  position: relative;
  width: 250px;
  height: 200px;
  overflow: hidden;
}

.creature-foreground {
  z-index: 1;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.creature-machine-image-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  background-color: green;
  overflow: hidden;
}

.creature-machine-info {
  width: 200px;
  margin-left: 25px;
  border-radius: 6px;
  padding: 10px;
  background-color:hsla(61, 82%, 81%, 0.95);
  color: hsla(61, 82%, 20%, 0.95);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 6px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.creature-machine-detail-line {
  position: absolute;
  width: 92%;
  height: 1%;
  opacity: 0.2;
  background-color: black;
  left: 26px;
  bottom: 2%;
}

.line-1 {
  bottom: 1%;
}

.line-2 {
  bottom: 3%;
}

.creature-machine-title {
  position: absolute;
  left: 27px;
  bottom: 20px;
  font-size: 30px;
  opacity: 0.5;
  width: 242px;
  background: rgba(0,0,0,0.3);
  padding: 5px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .creature-machine {
    flex-direction: column;
  }

  .creature-machine-info {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 55px;
    width: 92%;
  }
}