.stars {
  animation: moveUpDown 100s linear infinite;
  width: 1px;
  height: 1px;
  background: transparent;
  position: absolute;
  top: 0;
  opacity: 0.7;
  transition: opacity 2s linear;
}
.stars::after {
  content: " ";
  position: absolute;
  top: 0;
  width: 1px;
  height: 1px;
  background: transparent;
}

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

@keyframes moveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}