body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homepage {
  background: eggshell;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Trebuchet MS";
  font-size: 80px;
  background: -webkit-radial-gradient(center, ellipse, rgba(30,87,153,0.1) 0%,rgba(125,185,232,0) 100%);
  background: radial-gradient(ellipse at center, rgba(30,87,153,0.1) 0%,rgba(125,185,232,0) 100%); ;
}

.homepage a {
  text-decoration: none;
  color: #626567;
}

.homepage-choice-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  max-width: 850px;
  height: 50%;
}

.homepage-big {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transition: all linear 50ms;
  transition: all linear 50ms;
  cursor: pointer;
  border-top: solid #B3B6B7 1px;
  border-bottom: solid #B3B6B7 1px;
  color: #7B7D7D;
}

.homepage-big:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  border: rgb(255, 235, 119) solid 2px;
  border-radius: 8px;
  box-shadow: rgba(235, 213, 90, 0.3) 0px 0px 90px 12px;
}

.homepage-big-left {
  background: #FDFEFE;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: solid #B3B6B7 1px;
}

.homepage-big-right {
  background: #F7F9F9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: solid #B3B6B7 1px;
}

@media only screen and (max-width: 600px) {
  .homepage {
    font-size: 40px;
  }

  .homepage-choice-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .homepage-big {
    width: 80%;
  }

  .homepage-big {
    border-left: solid #B3B6B7 1px;
    border-right: solid #B3B6B7 1px;
  }

  .homepage-big-left {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom: none;
    border-top: solid #B3B6B7 1px;
  }
  
  .homepage-big-right {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0px;
    border-top: none;
    border-bottom: solid #B3B6B7 1px;
  }
}
.sky-sphere-center {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: translateY(8%);
          transform: translateY(8%);
}

.sky-sphere {
  border-radius: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 30px #9ecaed;
  -webkit-transition: -webkit-transform 300ms linear;
  transition: -webkit-transform 300ms linear;
  transition: transform 300ms linear;
  transition: transform 300ms linear, -webkit-transform 300ms linear;
}

.sky-sphere-hue {
  box-shadow: 0 0 80px #e5ff6f;
}
.stars {
  -webkit-animation: moveUpDown 100s linear infinite;
          animation: moveUpDown 100s linear infinite;
  width: 1px;
  height: 1px;
  background: transparent;
  position: absolute;
  top: 0;
  opacity: 0.7;
  -webkit-transition: opacity 2s linear;
  transition: opacity 2s linear;
}
.stars::after {
  content: " ";
  position: absolute;
  top: 0;
  width: 1px;
  height: 1px;
  background: transparent;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}

@-webkit-keyframes moveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}

@keyframes moveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}
.time-widget-wrapper {
  background-color: black;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.sky {
  background-color: lightblue;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.time-land {
  width: 100%;
  height: 100%;
  background-color: green;
  border-radius: 100%;
}

.time-land-wrapper {
  width: 100%;
  height: 50%;
  bottom: 0;
  position: absolute;
  background: darkgreen;
  border-radius: 100%;
  -webkit-transform: translateY(100%) scale(2);
          transform: translateY(100%) scale(2);
  z-index: 1;
}

.light-filter {
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 2s linear;
  transition: opacity 2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.land {
  border-radius: 100%;
}

.time-land-night {
  background: rgb(3, 22, 3);
}
.time-land-night-peak {
  background: rgb(0, 48, 0);
}
.time-land-morning {
  background: -webkit-linear-gradient(top, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-morning-peak {
  background: -webkit-linear-gradient(top, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-noon {
  background: -webkit-linear-gradient(top, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-noon-peak {
  background: -webkit-linear-gradient(top, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-evening {
  background: -webkit-linear-gradient(top, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);
  background: linear-gradient(to bottom, #83c783 6%,#52b152 16%,#008a00 23%,#005700 100%);;
}
.time-land-evening-peak {
  background: rgb(22, 56, 22);
}

.time-sky-night {
  background: rgb(0, 0, 66);
}
.time-sky-night-peak {
  background: black;
}
.time-sky-morning {
  background: rgb(0, 0, 133);;
}
.time-sky-morning-peak {
  background: -webkit-linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
  background: linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
}
.time-sky-noon {
  background: lightblue;
}
.time-sky-noon-peak {
  background: lightblue;
}
.time-sky-evening {
  background: -webkit-linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
  background: linear-gradient(#C6E4EE 0%, #C6E4EE 40%, #FED1AE 60%, #FAA0B9 70%, #CB7DCB 80%, #757ECB 100%);
}
.time-sky-evening-peak {
  background: darkblue;
}
.the-button {
  padding: 10px 35px;
  background: cornflowerblue;
  border: none;
  color: ghostwhite;
  font-family: monospace;
  color: #fff;
  background-color: #6496c8;
  border-radius: 15px;
  box-shadow: 0 5px #27496d;
  font-size: 1.5em;
  cursor: pointer;
  position: fixed;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: top linear 0.5s, left linear 0.5s;
  transition: top linear 0.5s, left linear 0.5s;
}

.the-button:disabled {
  cursor: not-allowed;
  background: red;
  box-shadow: 0 5px darkred;
}

.the-rest {
  width: 100%;
  background-color: LightYellow;
  overflow: hidden;
}

.lava-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.attribute-bold {
  font-weight: 600;
}

.creature-machine {
  display: -webkit-flex;
  display: flex;
  position: relative;
  background-color: hsl(0, 100%, 70%);
  padding: 25px;
  border-radius: 8px;
  border: solid hsl(0, 100%, 50%) 3px;
  box-shadow: 0px 0px 100px black;
}

.creature-machine-image {
  background-color: white;
  border-radius: 8px;
  border: solid hsl(0, 100%, 50%) 3px;
  position: relative;
  width: 250px;
  height: 200px;
  overflow: hidden;
}

.creature-foreground {
  z-index: 1;
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.creature-machine-image-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  background-color: green;
  overflow: hidden;
}

.creature-machine-info {
  width: 200px;
  margin-left: 25px;
  border-radius: 6px;
  padding: 10px;
  background-color:hsla(61, 82%, 81%, 0.95);
  color: hsla(61, 82%, 20%, 0.95);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 6px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.creature-machine-detail-line {
  position: absolute;
  width: 92%;
  height: 1%;
  opacity: 0.2;
  background-color: black;
  left: 26px;
  bottom: 2%;
}

.line-1 {
  bottom: 1%;
}

.line-2 {
  bottom: 3%;
}

.creature-machine-title {
  position: absolute;
  left: 27px;
  bottom: 20px;
  font-size: 30px;
  opacity: 0.5;
  width: 242px;
  background: rgba(0,0,0,0.3);
  padding: 5px;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .creature-machine {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .creature-machine-info {
    margin: 0;
    margin-top: 12px;
    margin-bottom: 55px;
    width: 92%;
  }
}
.mating-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.mating-center-wrapper {
  display: -webkit-flex;
  display: flex;
  /* width: 100%; */
  text-align: center;
  padding: 20px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.mating-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.mating-parents {
  background-color: rgb(131, 102, 102);
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.mating-children {
  background-color: rgb(187, 146, 146);
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.mating-creature-wrapper {
  background-color: rgba(255,255,255,0.3);
  margin: 10px;
  padding: 20px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.mating-creature-wrapper:hover {
  box-shadow: 0 0 10px white;
}

.mating-title {
  color: rgba(255,255,255,0.4);
  font-size: 35px;
  font-weight: bold;
}

.mating-creature-info-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0,0,0,0.5);
  color: ghostwhite;
  cursor: pointer;
}

.mating-creature-info-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.creature-shown-outer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 1000;
  cursor: zoom-out;
}

@media only screen and (max-width: 600px) {
  .hide-on-mobile {  
    display: none;
  }
}
.sequence-page-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.sequence-page-wrapper-inner {
  width: 800px;
  height: 500px;
  max-width: 90%;
  max-height: 90%;
}

.all-sequences-wrapper-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  margin: 10px;
}

.all-sequences-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 10px;
}

.sequence-wrapper {
  margin-bottom: 5px;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
}

.sequence-note-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
}

.sequence-note {
  background-color: lightblue;
  border-radius: 2px;
  margin-left: 5px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  cursor: pointer;
}

.sequence-note-sound {
  background-color: rgba(0,0,0,0.5);
  color: white;
  height: 100%;
  padding: 0 5px;
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  width: 35px;
}

.sequence-note-sound:hover {
  background-color: rgba(0,0,0,0.7);
}

.sequence-add-note {
  background-color: rgba(0,0,0,0.1);
  font-size: 30px;
  color: white;
  height: 100px;
  max-height: 100%;
  padding: 5px;
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  width: 30px;
}

.sequence-add-note:hover {
  background-color: rgba(0,0,0,0.3);
}

.sequence-add-sequence {
  background-color: rgba(0,0,0,0.1);
  font-size: 30px;
  color: white;
  padding: 5px;
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  width: 100px;
  max-width: 50%;
  height: 30px;
}

.sequence-add-sequence:hover {
  background-color: rgba(0,0,0,0.3);
}

.sequence-note-filled {
  background-color: coral;
}

.sequence-note-playing {
  opacity: 0.6;
}

.all-sequences-adding-container {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.sequence-choose-your-note {
  position: fixed;
  z-index: 10;
  padding: 10px;
  background: blue;
}

.sequence-note-sound-editing {
  background-color: black;
  box-shadow: 0 0 0px 5px white;
  position: relative;
  z-index: 15;
}
.music-detail-picker-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 10;
  background: rgba(0,0,0,0.4);
  cursor: zoom-out;
}

.music-detail-picker {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: space-between;
          align-items: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: rgba(193, 193, 193, 0.9);
  color: white;
  padding: 20px;
  border-radius: 3px;
  border: white solid 5px;
  cursor: auto;
}

.music-detail-button {
  cursor: pointer;
  background: white;
  border: none;
  margin: 2px;
  border-radius: 1px;
}
.music-detail-button-submit {
  margin-top: 20px;
  background: ghostwhite;
}

.music-detail-section {
  margin-bottom: 3px;
}

.music-detail-button-selected {
  box-shadow: 0 0 0 2px gold;
}

.music-detail-submission {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
p {
  padding: 0.2rem 0;
  margin: 0;
}
.container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: lightgoldenrodyellow;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}

.little-button {
  width: 20%;
  height: 10%;
  background-color: cornflowerblue;
  border-radius: 4px;
}

.little-button:hover {
  background-color: red;
}
.showcase {
  padding: 5px;
  border-radius: 100%;
  box-shadow: 5px 0px 20px #888888;
  opacity: 0.8;
  -webkit-transition: opacity 300ms ease-out;
  transition: opacity 300ms ease-out;
}

.showcase a {
  color: inherit;
}

.showcase:hover {
  opacity: 0.95;
}

.showcase-organizer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 40px;
}

.showcase-metadata {
  margin-top: 20px;
  text-align: center;
}

.showcase-title {
  font-size: 36px;
  opacity: 0.5;
  font-weight: bold;
  margin-top: 12px;
}

.showcase-description {
  display: none;
}
.play {
  background: eggshell;
  padding: 5%;
  padding-top: 10px;
  text-align: center;
  color: #626567;
}

.play-projects {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto;
  margin-top: 10%;
  width: 80%;
}

.showcase-wrapper {
  margin: 0 60px 60px 60px;
}

.preview-wrapper {
  border-radius: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  width: 100%;
}
.preview-sequence-wrapper {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 100%;
}

.preview-sequence {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.preview-sequence-square {
  background-color: lightblue;
  border-radius: 2px;
  margin: 2px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  opacity: 0.9;
  -webkit-transition: opacity linear 200ms;
  transition: opacity linear 200ms;
}

.preview-sequence-square:hover {
  opacity: 1;
  background-color: coral;
}
.tech-skills-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 20px;
  width: 600px;
  max-width: 95%;
  margin: 0 auto;
}

.explanation-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 20px;
  width: 660px;
  max-width: 95%;
  margin: 0 auto;
}

.skill-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 0 2px rgba(240, 239, 233, 0.8);
  border-radius: 3px;
  margin: 0 10px 10px 0;
  border: solid 2px gray;
}

.skill-name {
  font-weight: 600;
  font-size: 16px;
}

.skill-time {
  font-size: 15px;
}

.tech-skills-skill-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.core-strengths-explain {
  margin-bottom: 10px;
}

.work-wrapper {
  background: rgb(255, 255, 251);
  overflow: hidden;
}

.work {
  color: rgb(98, 101, 103, 0.8);
  width: 800px;
  max-width: 94%;
  margin: 0 auto;
  font-size: 14px;
}

.work-title {
  text-align: center;
}

.bold-it {
  font-weight: 600;
  font-size: 20px;
}

.fe-focus {
  font-weight: 600;
  font-size: 20px;
  background: -webkit-radial-gradient(circle, rgb(0 106 255) 0%, rgb(255 0 231) 100%);
  background: radial-gradient(circle, rgb(0 106 255) 0%, rgb(255 0 231) 100%);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro-wrapper {
  width: 283px;
  padding: 10px 20px;
}

.intro-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.intro-text-right {
  text-align: right;
}

.intro-sec {
  margin: 40px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: bottom;
          align-items: bottom;
}

.intro {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  border-bottom: 2px solid rgb(196, 196, 193, 0.2);
}

.intro-hint {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.intro-hint a {
  color: inherit;
  font-weight: 600;
}

.core-strengths {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 2px solid rgb(196, 196, 193, 0.2);
  padding: 30px 0;
}

.wordy-section {
  margin-bottom: 30px;
}

.wordy-section-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 3px;
  font-family: sans-serif;
}
* {
  box-sizing: border-box;
}
