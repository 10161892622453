.showcase {
  padding: 5px;
  border-radius: 100%;
  box-shadow: 5px 0px 20px #888888;
  opacity: 0.8;
  transition: opacity 300ms ease-out;
}

.showcase a {
  color: inherit;
}

.showcase:hover {
  opacity: 0.95;
}

.showcase-organizer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.showcase-metadata {
  margin-top: 20px;
  text-align: center;
}

.showcase-title {
  font-size: 36px;
  opacity: 0.5;
  font-weight: bold;
  margin-top: 12px;
}

.showcase-description {
  display: none;
}