.tech-skills-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
  width: 600px;
  max-width: 95%;
  margin: 0 auto;
}

.explanation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  width: 660px;
  max-width: 95%;
  margin: 0 auto;
}

.skill-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: 0 0 2px rgba(240, 239, 233, 0.8);
  border-radius: 3px;
  margin: 0 10px 10px 0;
  border: solid 2px gray;
}

.skill-name {
  font-weight: 600;
  font-size: 16px;
}

.skill-time {
  font-size: 15px;
}

.tech-skills-skill-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.core-strengths-explain {
  margin-bottom: 10px;
}
