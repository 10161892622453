.sky-sphere-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(8%);
}

.sky-sphere {
  border-radius: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 30px #9ecaed;
  transition: transform 300ms linear;
}

.sky-sphere-hue {
  box-shadow: 0 0 80px #e5ff6f;
}